var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-content',[_c('div',{staticClass:"nav"},[_c('a-breadcrumb',[_c('a-breadcrumb-item',[_vm._v("订单管理")]),_c('a-breadcrumb-item',[_vm._v("已交付订单")])],1)],1),_c('div',{staticClass:"content-container"},[_c('a-form',{staticClass:"advanced-search-form",attrs:{"form":_vm.form},on:{"submit":_vm.onSearch}},[_c('a-row',{staticClass:"row"},[_c('a-col',{attrs:{"span":21}},[_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"订单编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['orderSn']),expression:"['orderSn']"}],attrs:{"allow-clear":""}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"创建时间"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['time']),expression:"['time']"}],on:{"change":_vm.onChangeTime}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"经销商"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['businessVenuName']),expression:"['businessVenuName']"}],attrs:{"allow-clear":""}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"收货人姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['consigneeName']),expression:"['consigneeName']"}],attrs:{"allow-clear":""}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"收货人电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'consigneeTel',
                    {
                      rules: [
                        {
                          pattern: /^[0-9]{0,11}$/,
                          message: '手机号格式不正确'
                        }
                      ]
                    }
                  ]),expression:"[\n                    'consigneeTel',\n                    {\n                      rules: [\n                        {\n                          pattern: /^[0-9]{0,11}$/,\n                          message: '手机号格式不正确'\n                        }\n                      ]\n                    }\n                  ]"}],attrs:{"allow-clear":""}})],1)],1)],1)],1),_c('a-col',{staticClass:"col",attrs:{"span":3}},[_c('a-button',{staticClass:"search-button",attrs:{"type":"primary","icon":"search"},on:{"click":_vm.onSearch}},[_vm._v(" 搜索 ")]),_c('a-button',{staticClass:"btn-reset",attrs:{"icon":"undo"},on:{"click":_vm.onReset}},[_vm._v(" 重置 ")])],1)],1)],1),_c('div',{staticClass:"main-content"},[_c('a-table',{attrs:{"data-source":_vm.tblData,"columns":_vm.tblColumns,"pagination":_vm.pagination,"loading":_vm.loading,"row-key":function (record, index) {
            return index;
          }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"orderSn",fn:function(text, record){return [_c('div',{staticClass:"ordersn-container"},[_c('div',[_vm._v(_vm._s(record.orderSn))]),(record.porderSn)?_c('div',{staticClass:"order-forword"},[_c('img',{staticClass:"icon-forword",attrs:{"src":require("../../assets/order/icon-forword.png"),"alt":"远期订单"}}),_vm._v(" "+_vm._s(record.porderSn)+" ")]):_vm._e()])]}},{key:"businessVenuName",fn:function(text, record){return [_c('div',[_c('div',[_vm._v(_vm._s(record.dealerName))]),_c('div',{staticClass:"dealer-type"},[_vm._v(" "+_vm._s(record.dealerType)+_vm._s(record.storeType ? "(" + record.storeType + ")" : "")+" ")])])]}},{key:"action",fn:function(text, record){return [_c('a',{on:{"click":function($event){return _vm.onDeliveredOrderDetail(record.orderSn)}}},[_vm._v("查看详情")])]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }